// logos-items-list
.logos-items-list {
	list-style: none;
	margin: 0 -2.4rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> li {
		padding: 1.5rem 2.4rem;
	}

	figure {
		margin: 0;
		position: relative;
		overflow: hidden;

		img,
		svg {
			display: inline-block;
			vertical-align: top;
			max-width: 8.7rem;
			max-height: 7.5rem;
		}

		svg {
			path {
				fill: #{$body-color};
			}
		}
	}

	@include media-breakpoint-down(lg) {
		margin-left: -1.4rem;
		margin-right: -1.4rem;

		> li {
			padding: 1rem 1.4rem;
		}

		figure {
			img,
			svg {
				max-width: 5.2rem;
				max-height: 4.5rem;
			}
		}
	}
}
