// mobile-nav
.mobile-nav {
	font-size: 4rem;
	line-height: 1.053;
	font-weight: $font-family-secondary-weight;
	font-family: $font-family-secondary;
	text-transform: uppercase;

	> ul {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			padding-bottom: 2.8rem;

			&:last-child {
				padding-bottom: 0;
			}

			> a {
				text-decoration: none;
				color: inherit;

				&:hover,
				&:focus {
					text-decoration: none;
					color: inherit;
				}
			}

			&.active {
				> a {
					color: var(--primary-color);
				}
			}
		}
	}
}
