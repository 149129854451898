// btn-big
.btn-big {
	display: block;
	text-decoration: none;
	position: relative;
	border-radius: 1.6rem;
	padding: 1.8rem 2.9rem 1.9rem;
	background-color: var(--secondary-color);
	color: var(--light-color);
	font-size: 4rem;
	line-height: 1.025;
	font-weight: $font-family-secondary-weight;
	font-family: $font-family-secondary;
	text-transform: uppercase;
	box-shadow: 0 1rem 1.2rem -0.7rem rgba($black, 0.4);

	&::after {
		content: "";
		position: absolute;
		z-index: 5;
		top: 2.1rem;
		right: 2.1rem;
		width: 1.9rem;
		height: 1.5rem;
		background: url(#{$images}arrow-right-brown-01.svg) no-repeat;
		background-size: 1.9rem 1.5rem;
	}

	small {
		display: block;
		font-size: 1.9rem;
		line-height: 1;
		font-weight: inherit;
		padding-bottom: 1.5rem;
		color: $body-color;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		background-color: var(--secondary-shade-color);
		color: var(--light-color);
	}

	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;

		&::after {
			display: none;
		}
	}

	@include media-breakpoint-down(lg) {
		display: inline-flex;
		vertical-align: top;
		align-items: center;
		flex-wrap: wrap;
		border-radius: 1rem;
		padding: 0.5rem 5rem 0.5rem 1.5rem;
		font-size: 2.3rem;
		line-height: 1.13;
		width: 100%;
		min-height: 7rem;

		&::after {
			top: 50%;
			right: 1.8rem;
			transform: translate(0, -50%);
		}

		strong {
			max-width: 100%;
			flex: 0 0 100%;
			color: $body-color;
			font-weight: inherit;
			margin-top: -0.4rem;
		}
	}
}
