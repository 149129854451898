// logos-bar
.logos-bar {
	position: relative;
	overflow: hidden;
	padding-top: 4.2rem;
	padding-bottom: 3.4rem;
	border-top: 1px solid rgba($body-color, 0.3);

	@include media-breakpoint-down(lg) {
		padding-top: 2.4rem;
		padding-bottom: 6.2rem;
	}
}
