// detail-area
.detail-area {
	position: relative;
	padding-top: 4.5rem;
	padding-bottom: 6rem;

	.detail-area-body {
		padding-left: 11.8rem;
	}

	.detail-area-sidebar {
		max-width: 34.5rem;
		margin-left: auto;
		padding-top: 0.8rem;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 1rem;
		padding-bottom: 4rem;

		.detail-area-body {
			padding-left: 0;
		}

		.detail-area-sidebar {
			max-width: none;
			padding-top: 0;
			position: relative;
			overflow: hidden;
		}
	}
}
