// related-section
.related-section {
	position: relative;
	overflow: hidden;
	padding-top: 5.5rem;
	padding-bottom: 6.7rem;

	h2 {
		font-size: 2.1rem;
		line-height: 1.2;
		font-weight: inherit;
		font-family: inherit;
		margin-bottom: 4.5rem;
		text-transform: none;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 4.5rem;
		padding-bottom: 3.2rem;

		h2 {
			font-size: 1.5rem;
			margin-bottom: 4rem;
		}
	}
}
