// social
.social {
	list-style: none;
	margin: 0 -1.4rem;
	padding: 0 0 3rem;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	> li {
		padding-left: 1.4rem;
		padding-right: 1.4rem;
		display: flex;

		> a {
			position: relative;
			overflow: hidden;
			display: flex;

			img,
			svg {
				display: inline-block;
				vertical-align: top;
				max-width: 2.6rem;
				max-height: 2.3rem;
			}

			svg {
				path {
					fill: var(--secondary-color);
				}
			}

			&:hover {
				opacity: $hover-opacity;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding-bottom: 1.2rem;
	}
}
