// single-area
.single-area {
	position: relative;
	overflow: hidden;
	padding-bottom: 7rem;

	.single-area-main {
		padding-bottom: 36.1rem;
		padding-left: 11.6rem;
		position: relative;
	}

	.intro-info {
		h1 {
			margin-bottom: 2.8rem;
		}

		h2 {
			margin-bottom: 0.9rem;
		}

		p {
			margin-bottom: 2.2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.social {
			padding-bottom: 2.7rem;
		}
	}

	.images-carousel {
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 100%;
		padding-left: 15.4%;
	}

	.single-area-body {
		padding-top: 1.9rem;

		h2 {
			margin-bottom: 1.7rem;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}

			+ h2 {
				padding-top: 1.2rem;
			}
		}

		.btn-row {
			padding-top: 0.5rem;
			padding-bottom: 2.9rem;

			&:last-child {
				padding-bottom: 0;
			}
		}

		.single-area-note {
			display: block;
			line-height: 1.19;
			color: var(--secondary-color);

			& + .sidebar-section {
				margin-top: 1.7rem;

				@include media-breakpoint-up(lg) {
					margin-top: 1.1rem;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.images-carousel {
			&.carousel-center-mode {
				padding-left: 0;

				.swiper-wrapper {
					justify-content: center;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.single-area-main {
			> .row {
				> div[class^="col-"] {
					&:nth-child(1) {
						width: 45.4%;
					}

					&:nth-child(3) {
						width: 54.6%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 1.4rem;
		padding-bottom: 6rem;

		.single-area-main {
			padding-bottom: 0;
			padding-left: 0;
		}

		.intro-info {
			padding-bottom: 1.1rem;

			h1 {
				margin-bottom: 1.4rem;
			}

			h2 {
				margin-bottom: 1.1rem;
				font-size: 2.3rem;
			}

			p {
				margin-bottom: 3rem;
			}

			.social {
				padding-bottom: 1.2rem;
			}
		}

		.images-carousel {
			position: relative;
			bottom: auto;
			left: auto;
			right: auto;
			padding-left: 4.3rem;
			padding-right: 4.3rem;
			margin-bottom: 3.6rem;
		}

		.single-area-body {
			padding-top: 0;

			h2 {
				font-size: 2.3rem;
				margin-bottom: 1.9rem;
			}

			.btn-row {
				padding-top: 2rem;
				padding-bottom: 2.3rem;

				&:last-child {
					padding-bottom: 0;
				}
			}

			.single-area-note {
				line-height: inherit;
			}
		}
	}
}
