// hero-banner
.hero-banner {
	margin: 0;
	position: relative;
	// overflow: hidden;
	min-height: calc((var(--vh, 1vh) * 100) - 8rem);
	background-color: $black;
	color: var(--light-color);

	.hero-banner-image {
		@include position-over(5);

		overflow: hidden;

		&::before {
			content: "";

			@include position-over(7);

			background-color: $black;
			opacity: 0.6;
		}

		img {
			@include object-fit-cover();

			z-index: 5;
		}

		video {
			@include object-fit-cover();

			z-index: 6;
		}
	}

	.hero-banner-link {
		@include position-over(8);
		@include blank-text();

		overflow: hidden;
	}

	figcaption {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: calc((var(--vh, 1vh) * 100) - 8rem);
		position: relative;
		z-index: 10;
		line-height: 1.19;
		pointer-events: none;

		.hero-banner-body {
			width: 100%;
			margin-top: auto;
			padding-top: 5rem;

			.container {
				max-width: 133.4rem;
			}
		}

		.hero-banner-text {
			max-width: 50rem;
			padding-bottom: 6.5rem;
			pointer-events: all;
		}

		h1 {
			font-size: 12rem;
			line-height: 0.867;
			color: inherit;
			margin-bottom: 1rem;
		}

		h2 {
			font-size: 4rem;
			line-height: 1.025;
			margin-bottom: 2.2rem;
			color: inherit;
		}

		p {
			max-width: 48rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.hero-banner-footer {
			width: 100%;
			margin-bottom: auto;
			padding-bottom: 11.1rem;

			.container {
				max-width: 133.4rem;

				> * {
					pointer-events: all;
				}
			}
		}

		.btn-circle {
			margin-left: auto;
			margin-right: auto;
			top: 4.2rem;
			left: 2.6rem;
		}
	}

	&.hero-banner-extra {
		figcaption {
			font-size: 2.6rem;
			line-height: 1.192;

			.hero-banner-body {
				margin-top: 0;
				padding-top: 6.3rem;
			}

			.hero-banner-text {
				max-width: 90rem;
				padding-bottom: 8rem;
			}

			h1 {
				margin-bottom: 2.6rem;
			}

			h2 {
				font-size: 8rem;
				line-height: 0.938;
				margin-bottom: 1.4rem;
			}

			p {
				max-width: 60rem;
			}

			.hero-banner-footer {
				margin-bottom: 0;
				padding-bottom: 5rem;

				.btn {
					&.btn-lg {
						padding-left: 4rem;
						padding-right: 4rem;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.hero-banner-link {
			.cursor-circle-btn-visible & {
				cursor: none;
			}
		}
	}

	@include media-breakpoint-down(xxxl) {
		&.hero-banner-extra {
			figcaption {
				font-size: 2rem;

				.hero-banner-text {
					padding-bottom: 6rem;
					max-width: 53rem;
				}

				h1 {
					font-size: 7rem;
				}

				h2 {
					font-size: 4rem;
				}

				p {
					max-width: 45rem;
				}

				.hero-banner-footer {
					.btn {
						&.btn-lg {
							font-size: 2.3rem;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		min-height: calc((var(--vh, 1vh) * 100) - 6rem);

		figcaption {
			min-height: calc((var(--vh, 1vh) * 100) - 6rem);
			line-height: inherit;

			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 0.1rem;
				margin-bottom: auto;
			}

			.hero-banner-body {
				margin-bottom: auto;
				padding-top: 3rem;
				padding-bottom: 1.5rem;

				.container {
					max-width: 100%;
				}
			}

			.hero-banner-text {
				max-width: none;
				padding-bottom: 0;
			}

			h1 {
				font-size: 8rem;
				line-height: 0.875;
				margin-bottom: 2rem;
			}

			h2 {
				font-size: 3.5rem;
				line-height: 0.971;
				margin-bottom: 2rem;
			}

			p {
				max-width: none;
			}

			.hero-banner-footer {
				margin-top: auto;
				margin-bottom: 0;
				padding-top: 1.5rem;
				padding-bottom: 2.4rem;

				.container {
					max-width: 100%;
				}

				.btn {
					width: 100%;
					min-width: 0;
				}
			}
		}

		&.hero-banner-extra {
			figcaption {
				font-size: 1.9rem;
				line-height: 1.158;

				&::before {
					display: none;
				}

				.hero-banner-body {
					padding-top: 8.8rem;
					margin-bottom: 0;
				}

				.hero-banner-text {
					max-width: none;
					padding-bottom: 0;
				}

				h1 {
					font-size: 5rem;
					line-height: 0.88;
					margin-bottom: 2.6rem;
				}

				h2 {
					font-size: 3.5rem;
					line-height: 0.971;
					margin-bottom: 2.1rem;
				}

				p {
					max-width: none;
				}

				.hero-banner-footer {
					padding-top: 1.5rem;
					padding-bottom: 2.4rem;

					.btn {
						&.btn-lg {
							padding-left: 2rem;
							padding-right: 2rem;
						}
					}
				}
			}
		}
	}
}
