// vars
$fonts: '../fonts/';
$images: '../images/';
$fa-font-path: '../fonts/fontawesome';

// colors
$white:    #fff;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000;

$blue:    #6291a6;
$indigo:  #6610f2 !default;
$purple:  #c369a5;
$pink:    #e83e8c !default;
$red:     #c33e4a;
$orange:  #f0823c;
$yellow:  #d69c1c;
$green:   #4bb464;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$brown: #422b24;
$peach-cream: #ffefdb;
$pot-pourri: #f7e6e7;
$oasis: #fee8c8;
$akaroa: #dcccb8;
$wafer: #dbcacb;
$we-peep: #f7d9db;

$primary:       $blue;
$secondary:     $red;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $peach-cream;
$dark:          $gray-800 !default;

// bootstrap vars
$grid-breakpoints: (
	xs: 0,
	sm: 375px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
	xxxl: 1600px
);

$container-max-widths: (
	md: 75rem,
	lg: 98rem,
	xl: 117rem,
	xxl: 144rem
);

// grid
$grid-gutter-width: 3rem;

// container
$container-side-padding: 4rem;
$container-side-padding-xl: 3rem;
$container-side-padding-sm: 1.8rem;

// fonts
$FontAwesome: 'Font Awesome 6 Free';
$FontAwesomeBrands: 'Font Awesome 6 Brands';
$Barlow: 'Barlow', Arial, Helvetica, sans-serif;
$AmsiProCond: 'AmsiProCond', Arial, Helvetica, sans-serif;

$font-family-base: $Barlow;
$font-family-secondary: $AmsiProCond;

// settings
$body-bg: $light;
$body-color: $brown;
$font-size-base: 2.1rem;
$line-height-base: 1.381;
$font-weight-base: 500;
$tablet-font-size-base: 1.9rem;
$tablet-line-height-base: 1.158;
$mobile-font-size-base: 1.9rem;
$mobile-line-height-base: 1.158;

$font-family-secondary-weight: 900;

$link-color: $body-color;
$link-hover-color: $body-color;

// headings
$headings-font-family: $font-family-secondary;
$headings-font-weight: $font-family-secondary-weight;
$headings-line-height: 0.938;
$headings-color: $body-color;

$h1-font-size: 8rem;
$h2-font-size: 2.6rem;
$h3-font-size: 2.6rem;
$h4-font-size: 1.9rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;

// opacity
$hover-opacity: 0.3;

// dev 2

// dev 3

// dev 4

// dev 5
