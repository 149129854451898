// mobile-menu
.mobile-menu {
	position: fixed;
	z-index: 120;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	transition: all 0.3s;
	pointer-events: none;
	opacity: 0;
	background-color: var(--light-color);

	.menu-opened & {
		pointer-events: all;
		opacity: 1;
	}

	.mobile-menu-body {
		@include position-over(10);

		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	}

	.mobile-menu-heading {
		width: 100%;
		padding: 1.5rem 1.8rem;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.mobile-menu-languages {
		list-style: none;
		margin: 0 -1.5rem;
		padding: 0;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-size: 1.9rem;
		line-height: 1.053;
		font-weight: $font-family-secondary-weight;
		font-family: $font-family-secondary;
		text-transform: uppercase;
		flex-grow: 1;

		> li {
			padding-left: 1.5rem;
			padding-right: 1.5rem;

			> a {
				text-decoration: none;
				color: inherit;

				&:hover {
					text-decoration: none;
					opacity: $hover-opacity;
				}
			}

			&.active {
				> a {
					color: var(--primary-color);

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	.mobile-menu-close {
		position: relative;
		overflow: hidden;
		padding: 0;
		cursor: pointer;
		width: 3rem;
		height: 3rem;
		background: none;
		border: 0;
		border-radius: 0;
		margin-left: 2.5rem;
		margin-right: -0.5rem;

		@include blank-text();

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 90%;
			height: 0.3rem;
			background-color: var(--primary-color);
			transition: all 0.3s;
			border-radius: 0.5rem;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	
		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	.mobile-menu-main {
		width: 100%;
		padding-top: 1.5rem;
		padding-left: 1.8rem;
		padding-right: 1.8rem;
		margin-top: auto;
		margin-bottom: auto;
	}

	.mobile-menu-user {
		padding-bottom: 3.3rem;
		color: var(--primary-color);
		font-size: 1.9rem;
		line-height: 1.053;
		font-weight: $font-family-secondary-weight;
		font-family: $font-family-secondary;
		text-transform: uppercase;

		> a {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			color: inherit;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: none;
				color: inherit;
			}
		}

		.mobile-menu-user-ico {
			max-width: 3.8rem;
			flex: 0 0 3.8rem;
			position: relative;
			overflow: hidden;
			margin-right: 1.7rem;

			img,
			svg {
				display: block;
				width: 100%;

				@include img-fluid();
			}

			svg {
				path {
					fill: var(--primary-color);
				}
			}
		}

		span {
			flex-grow: 1;
		}
	}

	.mobile-menu-footer {
		width: 100%;
		margin-top: auto;
		padding: 1.8rem;
	}

	.mobile-menu-social {
		list-style: none;
		margin: 0 -1rem;
		padding: 0 2.3rem;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;

		> li {
			padding-left: 1rem;
			padding-right: 1rem;
			display: flex;

			> a {
				position: relative;
				overflow: hidden;
				display: flex;

				img,
				svg {
					display: inline-block;
					vertical-align: top;
					max-width: 2.6rem;
					max-height: 2.3rem;
				}

				svg {
					path {
						fill: var(--primary-color);
					}
				}

				&:hover {
					opacity: $hover-opacity;
				}
			}
		}
	}

	.mobile-menu-logo-link {
		margin-top: 4rem;
		padding-top: 1.7rem;
		border-top: 1px solid currentColor;
		position: relative;

		> a {
			display: block;
			position: relative;
			overflow: hidden;

			&::after {
				content: "";
				position: absolute;
				z-index: 5;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				width: 1.9rem;
				height: 1.5rem;
				background: url(#{$images}arrow-right-brown-01.svg) no-repeat;
				background-size: 1.9rem 1.5rem;
			}
		}

		.logo {
			display: block;
			position: relative;
			overflow: hidden;
			width: 100%;
			max-width: 12.6rem;

			img {
				display: block;
				width: 100%;

				@include img-fluid();
			}
		}
	}

	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}
