// products-group
.products-group {
	list-style: none;
	margin: 0 -1rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	> li {
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		max-width: 33.33%;
		flex: 0 0 33.33%;

		> a {
			display: flex;
			width: 100%;
			color: inherit;
			text-decoration: none;

			.product-item {
				transition: all 0.3s;
			}

			&:hover,
			&:focus {
				color: inherit;
				text-decoration: none;

				.product-item {
					opacity: $hover-opacity;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		margin-left: -0.5rem;
		margin-right: -0.5rem;

		> li {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
}
