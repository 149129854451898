// instagram-gallery
.instagram-gallery {
	list-style: none;
	margin: 0 -1.2rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> li,
	#sb_instagram #sbi_images .sbi_item,
	.sbi_item {
		padding: 1.2rem !important;
		max-width: 25%;
		flex: 0 0 25%;
		display: flex;
		float: none;

		.sbi_photo_wrap,
		figure {
			margin: 0;
			position: relative;
			overflow: hidden;
			width: 100%;

			&::before {
				content: "";
				display: block;
				padding-top: 100%;
			}

			img {
				@include object-fit-cover();
				display: block !important;
			}

			a {
				height: auto !important;
			}
		}

		.sbi-screenreader {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	@include media-breakpoint-down(lg) {
		margin-left: -$container-side-padding-xl;
		margin-right: -$container-side-padding-xl;
		padding-left: 4.2rem;
		padding-right: 4.2rem;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		> li,
		#sb_instagram #sbi_images .sbi_item,
		.sbi_item {
			padding: 0 1.4rem !important;
			max-width: 27.8rem;
			flex: 0 0 27.8rem;

			.sbi_photo_wrap,
			figure {
				border-radius: 1.5rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		margin-left: -$container-side-padding-sm;
		margin-right: -$container-side-padding-sm;
	}

	#sb_instagram {
		padding-bottom: 0 !important;
		
		#sbi_images {
			padding: 0 !important;
			float: none;
			width: auto;
			display: flex;

			@include media-breakpoint-up(lg) {
				flex-wrap: wrap;
			}
		}
	}
}
