// product-item
.product-item {
	margin: 0;
	width: 100%;

	.product-item-image {
		position: relative;
		overflow: hidden;
		max-width: 8rem;
		margin-left: auto;
		margin-right: auto;

		&::before {
			content: "";
			display: block;
			padding-top: 100%;
		}

		img {
			@include center-image(100%, 100%);
		}
	}

	figcaption {
		text-align: center;
		padding-top: 0.6rem;

		h4 {
			font-size: 1.6rem;
			line-height: 1.125;
			text-transform: uppercase;
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(lg) {
		.product-item-image {
			max-width: 5rem;
		}

		figcaption {
			padding-top: 0.6rem;

			h4 {
				font-size: 1.3rem;
			}
		}
	}
}
