// sidebar-section
.sidebar-section {
	border-top: 1px solid currentColor;
	padding-top: 1.1rem;
	padding-bottom: 2.7rem;

	h3 {
		font-size: 1.6rem;
		line-height: 1.2;
		font-weight: inherit;
		font-family: inherit;
		margin-bottom: 1.5rem;
	}

	.big-btns-group {
		padding-top: 1rem;
	}

	.products-group {
		padding-top: 1.1rem;
	}

	.services-list {
		margin-bottom: -1.5rem;
	}

	.gastroteca-widget {
		margin-top: 0.8rem;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 1.7rem;
		padding-bottom: 2.2rem;
		margin-top: -0.1rem;

		h3 {
			font-size: 1.3rem;
			margin-bottom: 1.1rem;
		}

		.products-group {
			padding-top: 0;
		}

		.services-list {
			margin-bottom: -0.4rem;
		}

		.gastroteca-widget {
			margin-top: 1.4rem;
		}
	}
}
