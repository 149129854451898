// main-nav
.main-nav {
	position: relative;
	z-index: 100;
	font-size: 1.9rem;
	line-height: 1.053;
	font-weight: $font-family-secondary-weight;
	font-family: $font-family-secondary;
	text-transform: uppercase;

	> ul {
		list-style: none;
		margin: 0 -1.4rem;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		> li {
			margin-left: 1.4rem;
			margin-right: 1.4rem;
			display: flex;

			> a {
				text-decoration: none;
				color: inherit;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}

			&:hover,
			&.active {
				> a {
					color: var(--primary-color);
				}
			}
		}
	}
}
