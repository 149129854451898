// footer
#footer {
	margin-top: auto;
	background-color: $brown;
	color: var(--light-color);
	font-size: 1.3rem;
	line-height: 1.538;
	position: relative;
	overflow: hidden;

	.container {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.footer-info-list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		> li {
			&::after {
				content: "·";
				margin-left: 0.6rem;
				margin-right: 0.6rem;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}

			> a {
				color: inherit;
				text-decoration: underline;

				&:hover {
					color: inherit;
					text-decoration: none;
				}
			}
		}
	}

	.footer-social {
		list-style: none;
		margin: 0 -2.3rem;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;

		> li {
			padding: 1rem 2.3rem;
			display: flex;

			> a {
				position: relative;
				overflow: hidden;
				display: flex;

				img,
				svg {
					display: inline-block;
					vertical-align: top;
					max-width: 2.6rem;
					max-height: 2.3rem;
				}

				svg {
					path {
						transition: all 0.3s;
						fill: var(--light-color);
					}
				}

				&:hover {
					svg {
						path {
							fill: var(--primary-color);
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.footer-social {
			padding-top: 2rem;
			justify-content: center;
		}
	}
}

// dev 2

// dev 3

// dev 4

// dev 5
