// product-section
.product-section {
	position: relative;
	overflow: hidden;

	.product-section-body {
		padding-top: 4.3rem;
		padding-bottom: 3rem;
		border-top: 1px solid currentColor;
	}

	mark {
		color: var(--secondary-color);
	}

	h2 {
		text-transform: none;
		margin-bottom: 4.1rem;
		font-size: 2.1rem;
		line-height: 1.2;
		font-weight: inherit;
		font-family: inherit;
	}

	h3 {
		text-transform: uppercase;
		margin-bottom: 1.8rem;
		font-size: 8rem;

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				text-decoration: none;
				color: var(--primary-color);
			}
		}
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	.product-section-image {
		margin: 0.3rem 0 0;
		position: relative;
		overflow: hidden;
		border-radius: 1.6rem;

		&::before {
			content: "";
			display: block;
			padding-top: 57%;
		}

		img {
			@include object-fit-cover();
		}
	}

	@include media-breakpoint-down(lg) {
		.product-section-body {
			padding-top: 1.1rem;
			padding-bottom: 6.5rem;
		}

		h2 {
			margin-bottom: 2.8rem;
			font-size: 1.5rem;
		}

		h3 {
			margin-bottom: 0.7rem;
			font-size: 5rem;
		}

		.product-section-image {
			margin-top: 2.1rem;

			&::before {
				padding-top: 73%;
			}
		}
	}
}
