// intro-area
.intro-area {
	position: relative;
	overflow: hidden;
	padding-bottom: 4rem;

	.intro-info {
		padding-left: 11.6rem;
	}

	.images-slideshow {
		margin-top: 0.6rem;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 1.4rem;
		padding-bottom: 1.5rem;

		.intro-info {
			padding-left: 0;
		}

		.images-slideshow {
			margin-top: 1.1rem;
		}
	}
}
