// header-dropdown
.header-dropdown {
	position: relative;

	@include blank-text();

	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	height: 8rem;

	.header-dropdown-toggle {
		background: none;
		padding: 0;
		border: 0;
		display: inline-block;
		vertical-align: top;
		position: relative;
		transition: all 0.3s;
		font-size: 1.9rem;
		line-height: 1.053;
		font-weight: $font-family-secondary-weight;
		font-family: $font-family-secondary;
		text-transform: uppercase;
		color: var(--primary-color);
	}

	.header-dropdown-menu {
		position: absolute;
		z-index: 100;
		top: 100%;
		left: 0;
		right: 0;
		padding-top: 0.3rem;
		transition: all 0.3s;
		opacity: 0;
		pointer-events: none;

		.header-dropdown-menu-body {
			border-radius: 0.6rem;
			background-color: var(--light-color);
			box-shadow: 0 0.1rem 0.8rem rgba($black, 0.25);
		}
	}

	&.header-dropdown-logo {
		width: 100%;
		max-width: 25rem;
		margin-left: -1.8rem;

		&::after {
			content: "";
			position: absolute;
			z-index: 5;
			bottom: 1.7rem;
			right: 1rem;
			width: 1.2rem;
			height: 0.7rem;
			background: url(#{$images}arrow-down-brown-01.svg) no-repeat;
			background-size: 1.2rem 0.7rem;
			transition: all 0.3s;
			pointer-events: none;

			.scrolled & {
				opacity: 0;
			}
		}

		.logo {
			display: block;
			position: relative;
			overflow: hidden;

			img {
				display: block;
				width: 100%;

				@include img-fluid();
			}
		}

		.header-logo-sticky {
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 1.7rem;
			transform: translate(0, -50%);
			width: 8.7rem;
			overflow: hidden;
			margin-top: -0.2rem;
			transition: all 0.3s;
			opacity: 0;
			pointer-events: none;

			.scrolled & {
				opacity: 1;
				pointer-events: all;
			}

			img {
				display: block;
				width: 100%;

				@include img-fluid();
			}
		}

		.header-dropdown-toggle {
			padding-bottom: 0.1rem;
			padding-left: 1.7rem;
			padding-right: 3.5rem;

			.scrolled & {
				opacity: 0;
				pointer-events: none;
			}
		}

		.header-dropdown-menu {
			.scrolled & {
				opacity: 0;
				pointer-events: none;
			}

			.header-dropdown-menu-body {
				padding: 2.4rem 3.5rem 2.4rem 1.7rem;
			}
		}
	}

	&.header-dropdown-social {
		.header-dropdown-menu {
			left: auto;
			right: -2.3rem;

			.header-dropdown-menu-body {
				padding: 1.9rem 1.5rem;
			}
		}
	}

	&.header-dropdown-languages {
		.header-dropdown-menu {
			left: auto;
			right: -1.4rem;

			.header-dropdown-menu-body {
				padding: 2rem 2.6rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&:hover {
			.header-dropdown-toggle {
				opacity: $hover-opacity;
			}

			.header-dropdown-menu {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		&.header-dropdown-logo {
			display: block;
			height: auto;
			max-width: 16.8rem;
			margin-left: 0;

			&::after {
				display: none;
			}

			.header-logo-sticky {
				display: none;
			}

			.header-dropdown-toggle {
				padding-bottom: 0;
				padding-left: 0;
				padding-right: 0;

				.scrolled & {
					opacity: 1;
					pointer-events: all;
				}
			}

			.header-dropdown-menu {
				display: none;
			}
		}
	}
}
