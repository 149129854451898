@mixin blank-text() {
	font-size: 0;
	line-height: 0;
}

@mixin position-over($zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin center-image($maxWidth: 100%, $maxHeight: 100%, $zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	max-width: $maxWidth;
	max-height: $maxHeight;
}

@mixin grid-gutter($gutter-width) {
	margin-left: -$gutter-width * 0.5;
	margin-right: -$gutter-width * 0.5;

	> [class^="col"] {
		padding-left: $gutter-width * 0.5;
		padding-right: $gutter-width * 0.5;
	}
}

@mixin super-linear-gradient($angle, $color-stops...) {
	$_angle-with-vendor-prefix: "";
	$_angle: "";

	@if $angle == "to top" or $angle == "bottom" {
		$_angle-with-vendor-prefix: bottom;
		$_angle: to top;
	}

	@else if $angle == "to right" or $angle == "left" {
		$_angle-with-vendor-prefix: left;
		$_angle: to right;
	}

	@else if $angle == "to bottom" or $angle == "top" {
		$_angle-with-vendor-prefix: top;
		$_angle: to bottom;
	}

	@else if $angle == "to left" or $angle == "right" {
		$_angle-with-vendor-prefix: right;
		$_angle: to left;
	}

	@else if $angle == "to top right" or $angle == "bottom left" {
		$_angle-with-vendor-prefix: bottom left;
		$_angle: to top right;
	}

	@else if $angle == "to bottom right" or $angle == "top left" {
		$_angle-with-vendor-prefix: top left;
		$_angle: to bottom right;
	}

	@else if $angle == "to bottom left" or $angle == "top right" {
		$_angle-with-vendor-prefix: top right;
		$_angle: to bottom left;
	}

	@else if $angle == "to top left" or $angle == "bottom right" {
		$_angle-with-vendor-prefix: bottom right;
		$_angle: to top left;
	}

	@else {
		$_angle-with-vendor-prefix: $angle % 360;
		$_angle: (90 - $angle) % 360;
	}

	background: -webkit-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: -moz-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: -o-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: linear-gradient($_angle, $color-stops);
}

@mixin object-fit-cover() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin object-fit-contain() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@mixin input-position-over() {
	position: absolute;
	z-index: 12;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background: none;
	opacity: 0;
	padding: 0;
	overflow: hidden;
	text-indent: -9999px;
}

@mixin word-break() {
	word-break: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

@mixin reset-input-number() {
	-moz-appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none; 
		margin: 0;
	}
}

@mixin custom-underline($custom-underline-color: $body-color) {
	background-position: 0 100%;
	background-size: 1px 1px;
	background-image: linear-gradient(transparent 50%, $custom-underline-color 50%);
	background-repeat: repeat-x;
}

// dev 2
@mixin custom-scroll() {
	&::-webkit-scrollbar {
		width: 0.3rem;
		border-radius: 0.3rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 0.3rem;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba($primary, 0.2);
		border-radius: 0.3rem;
	}
}

@mixin reset-link() {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding-top: 0.2rem;
	padding-right: 2.4rem;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 1.25;
	font-family: $AmsiProCond;
	color: var(--opt-label-color);

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 1.6rem;
		height: 1.6rem;
		margin-top: -0.6rem;
		mask: url(#{$images}ico-times-001.svg) no-repeat;
		mask-size: 100% auto;
		background: currentColor;
	}

	&:hover {
		opacity: var(--hover-op);
	}
}

@mixin hideTimesFromSearch() {
	&::-ms-clear { display: none; width : 0; height: 0; }
	&::-ms-reveal { display: none; width : 0; height: 0; }
	
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration { display: none; }
}

// dev 3

// dev 4

// dev 5
