// intro-section
.intro-section {
	position: relative;
	overflow: hidden;
	padding-top: 9.7rem;
	padding-bottom: 14.3rem;
	text-align: center;
	font-size: 2.6rem;
	line-height: 1.192;

	.intro-section-heading {
		padding-bottom: 5.8rem;

		h2 {
			margin-bottom: 2.4rem;
			font-size: 8rem;
			color: inherit;
		}

		p {
			max-width: 77rem;
			margin-left: auto;
			margin-right: auto;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.image {
		margin: 0 0 3rem;
		position: relative;
		height: 9.9rem;

		img {
			position: absolute;
			z-index: 5;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			width: auto;
			max-width: none;
			height: 100%;
		}
	}

	.btn-row {
		padding-top: 5.5rem;
	}

	.intro-section-note {
		display: block;
		font-size: 1.6rem;
		line-height: 1.2;
		padding-top: 2.9rem;

		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				color: inherit;
				text-decoration: none;
			}
		}
	}

	@include media-breakpoint-down(xxxl) {
		.intro-section-heading {
			h2 {
				font-size: 6rem;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 3.8rem;
		padding-bottom: 4.9rem;
		font-size: 2.1rem;
		line-height: 1.19;

		.intro-section-heading {
			padding-bottom: 6.5rem;

			h2 {
				margin-bottom: 4.3rem;
				font-size: 5rem;
				line-height: 0.88;
			}

			p {
				max-width: none;
				margin-left: 0;
				margin-right: 0;
			}
		}

		.image {
			margin-bottom: 0;
			height: 9rem;
		}

		.btn-row {
			padding-top: 4rem;

			.btn {
				width: 100%;
				min-width: 0;
			}
		}

		.intro-section-note {
			font-size: 1.4rem;
			padding-top: 2rem;
		}
	}
}
