// instagram-section
.instagram-section {
	position: relative;
	overflow: hidden;
	padding-top: 9.9rem;
	padding-bottom: 31rem;

	.instagram-section-heading {
		padding-bottom: 4.6rem;

		h2 {
			margin-bottom: -0.5rem;
			color: inherit;
			font-size: 4rem;
			line-height: 1.025;
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 3.5rem;
		padding-bottom: 6rem;

		.instagram-section-heading {
			padding-bottom: 4.3rem;

			h2 {
				margin-bottom: 0;
				font-size: 3.5rem;
				line-height: 0.971;
			}
		}

		.btn-row {
			text-align: center;
			padding-top: 3.3rem;
		}
	}
}
