// intro-info
.intro-info {
	position: relative;

	mark {
		color: var(--secondary-color);
	}

	h1 {
		margin-bottom: 1.2rem;

		small {
			display: block;
			padding-bottom: 1.5rem;
			font-size: 2.6rem;
			line-height: 1.2;
			color: var(--secondary-color);
		}
	}

	.intro-info-contacts {
		list-style: none;
		margin: 0;
		padding: 0 0 1.7rem;
		color: var(--secondary-color);
		line-height: 1.19;

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				color: inherit;
				text-decoration: none;
				opacity: $hover-opacity;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		h1 {
			margin-bottom: 1.4rem;

			small {
				padding-bottom: 0.8rem;
				font-size: 2.3rem;
			}
		}

		.intro-info-contacts {
			padding-bottom: 2.1rem;
			line-height: 1.158;
		}
	}
}
