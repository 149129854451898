// btn-circle
.btn-circle {
	text-align: center;
	width: 12rem;
	height: 12rem;
	position: relative;
	overflow: hidden;
	border-radius: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	color: var(--light-color);
	background-color: var(--primary-color);
	font-size: 1.6rem;
	line-height: 1.2;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
		color: var(--light-color);
		background-color: var(--primary-shade-color);
	}
}
