// logos-group-list
.logos-group-list {
	list-style: none;
	margin: 0 -1rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	> li {
		padding: 1rem;
	}

	figure {
		margin: 0;
		position: relative;
		overflow: hidden;
		mix-blend-mode: multiply;

		img {
			display: inline-block;
			vertical-align: top;
		}
	}

	@include media-breakpoint-down(xxl) {
		justify-content: center;
	}

	@include media-breakpoint-down(lg) {
		justify-content: flex-start;

		figure {
			img {
				max-width: 19.2vw;
				max-height: 8vw;
			}
		}
	}
}
