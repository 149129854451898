// big-btns-widget
.big-btns-widget {
	padding-bottom: 3.8rem;

	h3 {
		font-size: 2.6rem;
		text-transform: uppercase;
		color: var(--secondary-color);
		margin-bottom: 2.1rem;
	}

	.btn-big {
		padding-top: 2.4rem;
		padding-bottom: 2.8rem;
	}

	.big-btns-widget-note {
		display: block;
		padding: 2.8rem 0.8rem 0.1rem;
		font-size: 1.6rem;
		line-height: 1.3125;
		color: var(--secondary-color);
	}
}
