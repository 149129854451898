// mobile-accordion
.mobile-accordion {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		padding-bottom: 3.6rem;

		&:last-child {
			padding-bottom: 0;
		}
	}

	mark {
		color: var(--secondary-color);
	}

	h2 {
		margin-bottom: 2.8rem;
	}

	.mobile-accordion-heading {
		position: relative;
	}

	h3 {
		font-size: 1.9rem;
		text-transform: uppercase;
		margin-bottom: 1.4rem;
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}

		+ h2 {
			padding-top: 1.1rem;
		}
	}

	.btn-row {
		padding-top: 0;
	}

	@include media-breakpoint-up(lg) {
		.mobile-accordion-heading {
			.mobile-accordion-opener {
				display: none !important;
			}
		}

		.mobile-accordion-collapse {
			display: block !important;
		}
	}

	@include media-breakpoint-down(lg) {
		padding-bottom: 1.9rem;

		> li {
			padding-bottom: 0;
			border-bottom: 0.1rem solid currentColor;
		}

		h2 {
			margin-bottom: 1.9rem;
			font-size: 2.3rem;
		}

		.mobile-accordion-heading {
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
			padding-right: 3rem;

			.mobile-accordion-opener {
				@include position-over(10);
				@include blank-text();

				width: 100%;
				height: 100%;
				background: none;
				border: 0;
				padding: 0;
				cursor: pointer;

				&::after {
					content: "";
					position: absolute;
					z-index: 5;
					top: 50%;
					right: 0.3rem;
					transform: translate(0, -50%) rotate(45deg);
					width: 1rem;
					height: 1rem;
					border: solid currentColor;
					border-width: 0 0.2rem 0.2rem 0;
					transition: all 0.3s;

					.opened & {
						transform: translate(0, -50%) rotate(225deg);
					}
				}
			}

			h2 {
				margin-bottom: 0;
			}
		}

		.mobile-accordion-collapse {
			display: none;
		}

		.mobile-accordion-body {
			padding-top: 0.5rem;
			padding-bottom: 1.5rem;
		}

		h3 {
			font-size: 1.9rem;
			line-height: 1.105;
			margin-bottom: 0.8rem;
		}

		p {
			+ h2 {
				padding-top: 1.9rem;
			}

			+ h3 {
				padding-top: 0.6rem;
			}
		}

		.btn-row {
			padding-top: 1.9rem;
			padding-bottom: 1.4rem;
		}

		.opened {
			.mobile-accordion-heading {
				.mobile-accordion-opener {
					&::after {
						transform: translate(0, -50%) rotate(225deg);
					}
				}
			}
		}
	}
}
