// logos-list
.logos-list {
	list-style: none;
	margin: 0 -1.5rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> li {
		padding: 1.5rem;
	}

	figure {
		margin: 0;
		position: relative;
		overflow: hidden;
		mix-blend-mode: multiply;

		img {
			display: inline-block;
			vertical-align: top;
		}
	}

	@include media-breakpoint-down(lg) {
		margin-left: -0.7rem;
		margin-right: -0.7rem;

		> li {
			padding: 0.7rem;
		}

		figure {
			img {
				max-width: 24vw;
				max-height: 10.6666666667vw;
			}
		}
	}
}
