// big-btns-group
.big-btns-group {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		padding-bottom: 0.9rem;

		&:last-child {
			padding-bottom: 0;
		}
	}

	@include media-breakpoint-down(lg) {
		> li {
			padding-bottom: 0.5rem;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
