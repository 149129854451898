// gastroteca-widget
.gastroteca-widget {
	position: relative;

	> a {
		display: block;
		color: inherit;
		text-decoration: none;

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
			opacity: $hover-opacity;
		}
	}

	.gastroteca-widget-body {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.gastroteca-widget-text {
		flex-grow: 1;

		h4 {
			margin-bottom: 0;
			font-size: 2.6rem;
			line-height: 1.192;
			text-transform: uppercase;
		}
	}

	.gastroteca-widget-logo {
		margin-left: 1.5rem;
		max-width: 2.3rem;
		flex: 0 0 2.3rem;
		position: relative;
		overflow: hidden;

		img {
			display: block;
			width: 100%;

			@include img-fluid();
		}
	}

	@include media-breakpoint-down(lg) {
		.gastroteca-widget-text {
			h4 {
				font-size: 2.3rem;
			}
		}

		.gastroteca-widget-logo {
			max-width: 1.6rem;
			flex: 0 0 1.6rem;
		}
	}
}
