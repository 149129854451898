// swiper-pagination
.swiper-pagination {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding-top: 1.7rem;
	margin-left: -0.5rem;
	margin-right: -0.5rem;

	.swiper-pagination-bullet {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		cursor: pointer;
		position: relative;
		border-radius: 100%;
		width: 1rem;
		max-width: 1rem;
		flex: 0 0 1rem;
		height: 1rem;
		transition: all 0.3s;
		background-color: var(--secondary-color);
		opacity: $hover-opacity;

		@include blank-text();

		&:hover,
		&:focus,
		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}
