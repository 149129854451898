// logos-section
.logos-section {
	padding-top: 3.1rem;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(lg) {
		padding-top: 3rem;
	}
}
