// color-theme-red
.color-theme-red {
	--primary-color: #{$secondary};
	--primary-shade-color: #{darken($secondary, 10%)};
	--secondary-color: #{$secondary};
	--secondary-shade-color: #{darken($secondary, 10%)};
	--light-color: #{$pot-pourri};
	--light-darken-color: #{$we-peep};
	--ico-color: #{$wafer};
}

// color-theme-blue-purple
.color-theme-blue-purple {
	--secondary-color: #{$purple};
	--secondary-shade-color: #{darken($purple, 10%)};
}

// color-theme-blue-orange
.color-theme-blue-orange {
	--secondary-color: #{$orange};
	--secondary-shade-color: #{darken($orange, 10%)};
}

// color-theme-blue-green
.color-theme-blue-green {
	--secondary-color: #{$green};
	--secondary-shade-color: #{darken($green, 10%)};
}

// color-theme-red-purple
.color-theme-red-purple {
	--primary-color: #{$secondary};
	--primary-shade-color: #{darken($secondary, 10%)};
	--secondary-color: #{$purple};
	--secondary-shade-color: #{darken($purple, 10%)};
	--light-color: #{$pot-pourri};
	--ico-color: #{$wafer};
}

// color-theme-red-orange
.color-theme-red-orange {
	--primary-color: #{$secondary};
	--primary-shade-color: #{darken($secondary, 10%)};
	--secondary-color: #{$orange};
	--secondary-shade-color: #{darken($orange, 10%)};
	--light-color: #{$pot-pourri};
	--ico-color: #{$wafer};
}

// color-theme-red-green
.color-theme-red-green {
	--primary-color: #{$secondary};
	--primary-shade-color: #{darken($secondary, 10%)};
	--secondary-color: #{$green};
	--secondary-shade-color: #{darken($green, 10%)};
	--light-color: #{$pot-pourri};
	--ico-color: #{$wafer};
}

// color-theme-red-yellow
.color-theme-red-yellow {
	--primary-color: #{$secondary};
	--primary-shade-color: #{darken($secondary, 10%)};
	--secondary-color: #{$yellow};
	--secondary-shade-color: #{darken($yellow, 10%)};
	--light-color: #{$pot-pourri};
	--ico-color: #{$wafer};
}

// color-theme-blue-yellow
.color-theme-blue-yellow {
	// --primary-color: #{$secondary};
	// --primary-shade-color: #{darken($secondary, 10%)};
	// --secondary-color: #{$yellow};
	// --secondary-shade-color: #{darken($yellow, 10%)};
	// --light-color: #{$pot-pourri};
	// --ico-color: #{$wafer};

	--secondary-color: #{$yellow};
	--secondary-shade-color: #{darken($yellow, 10%)};
}
