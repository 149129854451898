// guide-info-section
.guide-info-section {
	position: relative;
	overflow: hidden;
	background-color: var(--light-darken-color);

	.container {
		max-width: 134.2rem;
	}

	.guide-info-section-body {
		position: relative;
		padding-top: 14.4rem;
		padding-bottom: 11.4rem;
		padding-right: 45%;
	}

	h2 {
		margin-bottom: 5.4rem;
		font-size: 8rem;
	}

	.image {
		position: absolute;
		z-index: 5;
		top: 50%;
		right: -2rem;
		transform: translate(0, -50%);
		width: 42.7%;
		overflow: hidden;
		margin: 0;

		img {
			display: block;
			width: 100%;

			@include img-fluid();
		}
	}

	.btn-row {
		padding-top: 0;
	}

	@include media-breakpoint-down(lg) {
		.container {
			max-width: 100%;
		}

		.guide-info-section-body {
			padding-top: 4.1rem;
			padding-bottom: 2.8rem;
			padding-right: 0;
		}

		h2 {
			margin-bottom: 3.5rem;
			font-size: 5rem;
			line-height: 0.88;
		}

		.image {
			position: relative;
			top: auto;
			right: auto;
			transform: none;
			width: 67%;
			margin-left: auto;
			margin-right: auto;
		}

		.btn-row {
			padding-top: 5.2rem;

			.btn {
				display: block;
				min-width: 0;
				width: 100%;
			}
		}
	}
}
