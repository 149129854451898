// product-card
.product-card {
	margin: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	width: 100%;

	.product-card-image {
		position: relative;
		overflow: hidden;
		max-width: 40%;
		flex: 0 0 40%;
		margin-right: 1.5rem;
		border-radius: 1.6rem;

		&::before {
			content: "";
			display: block;
			padding-top: 81%;
		}

		img {
			@include object-fit-cover();
		}
	}

	figcaption {
		flex-grow: 1;

		small {
			display: block;
			padding-bottom: 1rem;
			font-size: 2.1rem;
			line-height: 1.2;
			font-weight: inherit;
		}

		h3 {
			text-transform: uppercase;
			margin-bottom: 0;
			font-size: 4rem;
			line-height: 1.025;
			color: var(--product-card-heading-color);
		}
	}

	@include media-breakpoint-down(lg) {
		.product-card-image {
			max-width: 12rem;
			flex: 0 0 12rem;

			&::before {
				padding-top: 100%;
			}
		}

		figcaption {
			small {
				padding-bottom: 1.4rem;
				font-size: 1.9rem;
			}

			h3 {
				font-size: 3.5rem;
				line-height: 0.971;
			}
		}
	}
}
