// logos-group
.logos-group {
	border-top: 1px solid rgba($body-color, 0.3);
	padding-top: 2.1rem;
	padding-bottom: 2.9rem;

	h4 {
		text-transform: uppercase;
		margin-bottom: 3.6rem;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 1.8rem;
		padding-bottom: 3.4rem;

		h4 {
			margin-bottom: 1.4rem;
			font-size: 1.6rem;
		}
	}
}
