// message-widget
.message-widget {
	background-color: var(--secondary-color);
	color: var(--light-color);
	padding: 1.5rem 1.5rem 1.2rem;
	border-radius: 1rem;
	box-shadow: 0 0.4rem 1rem -0.7rem rgba($black, 0.4);
	font-size: 1.3rem;
	line-height: 1.154;

	h3 {
		text-transform: uppercase;
		color: inherit;
		font-size: 2.3rem;
		margin-bottom: 1rem;
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
