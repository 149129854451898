// images-slideshow
.images-slideshow {
	position: relative;

	@include blank-text();

	.swiper-body {
		position: relative;
		overflow: hidden;
		border-radius: 1.6rem;
	}

	.swiper-container {
		position: relative;
		overflow: hidden;
		margin-left: -0.9rem;
		margin-right: -0.9rem;
	}

	.swiper-slide {
		padding-left: 0.9rem;
		padding-right: 0.9rem;
	}

	figure {
		margin: 0;
		position: relative;
		overflow: hidden;
		border-radius: 1.6rem;

		&::before {
			content: "";
			display: block;
			padding-top: 64.9%;
		}

		img {
			@include object-fit-cover();
		}

		&.with-video {
			.plyr,
			.video-box {
				@include position-over(0);
			}

			.plyr {
				.plyr__video-embed,
				.plyr__video-wrapper--fixed-ratio {
					aspect-ratio: 16 / 9 !important;
					position: absolute;
					top: 50%;
					left: 50%;
					height: auto;
					width: auto;
					min-width: 100%;
					min-height: 100%;
					transform: translate(-50%, -50%);
				}
			}

			.video-box {
				> iframe {
					@include position-over(0);
					width: 100%;
					height: 100%;
					min-width: 100%;
					min-height: 100%;
					max-width: 100%;
					max-height: 100%;
				}
			}

			video {
				@include object-fit-cover();
			}

			a {
				img {
					opacity: 0;
				}
			}
		}

		a {
			@include position-over(2);
		}
	}

	@include media-breakpoint-down(lg) {
		padding-left: 2.8rem;
		padding-right: 2.8rem;

		.swiper-body {
			overflow: visible;
			border-radius: 0;
		}

		.swiper-container {
			overflow: visible;
		}

		.swiper-slide {
			width: auto;
		}

		figure {
			width: 29.8rem;

			&::before {
				padding-top: 83.9%;
			}
		}
	}
}
