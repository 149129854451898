:root {
	--primary-color: #{$primary};
	--primary-shade-color: #{darken($primary, 10%)};
	--secondary-color: #{$primary};
	--secondary-shade-color: #{darken($primary, 10%)};
	--light-color: #{$light};
	--light-darken-color: #{$oasis};
	--ico-color: #{$akaroa};

	--header-height: 6rem;

	@include media-breakpoint-up(lg) {
		--header-height: 8rem;
	}
}

html {
	font-size: 10px;

	@include media-breakpoint-only(xl) {
		font-size: 9px;
	}

	@include media-breakpoint-only(lg) {
		font-size: 8px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 9px;
	}
}

body {
	margin: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	color: $body-color;
	background: var(--light-color);
	min-width: 320px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;

	.menu-opened & {
		overflow: hidden;
	}

	@include media-breakpoint-down(lg) {
		font-size: $tablet-font-size-base;
		line-height: $tablet-line-height-base;
	}

	@include media-breakpoint-down(md) {
		-webkit-text-size-adjust: none;
		font-size: $mobile-font-size-base;
		line-height: $mobile-line-height-base;
	}
}

img {
	border-style: none;
}

input,
textarea,
select {
	color: $black;
	vertical-align: middle;
	font: 100% $font-family-base;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: all 0.3s;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	border: none;
	padding: 0;
}

form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

blockquote,
q {
	quotes: none;

	&::before,
	&::after {
		content: "";
	}
}

a {
	color: $link-color;
	text-decoration: underline;
	transition: all 0.3s;
	word-wrap: break-word;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $link-hover-color;
	}
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
	outline: none !important;
}

header,
footer,
section,
nav,
article,
aside {
	display: block;
}

// wrapper
.wrapper {
	position: relative;
	min-height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	flex-direction: column;
}

// container
.container {
	&,
	&-fluid {
		--bs-gutter-x: calc(#{$container-side-padding} * 2);

		@include media-breakpoint-down(xxl) {
			--bs-gutter-x: calc(#{$container-side-padding-xl} * 2);
			max-width: 100%;
		}

		@include media-breakpoint-down(md) {
			--bs-gutter-x: calc(#{$container-side-padding-sm} * 2);
		}
	}
}

// headings
@for $i from 1 through 6 {
	h#{$i} {
		word-wrap: break-word;
		margin: 0 0 1.5rem;

		a {
			color: inherit;

			&:hover {
				color: inherit;
			}
		}
	}
}

h1 {
	text-transform: uppercase;

	@include media-breakpoint-down(xl) {
		font-size: 6rem;
	}

	@include media-breakpoint-down(lg) {
		font-size: 5rem;
		line-height: 0.88;
	}
}

h2 {
	text-transform: uppercase;
}

// p
p {
	margin: 0 0 2.9rem;

	@include media-breakpoint-down(lg) {
		margin-bottom: 1.5rem;
	}
}

// mark
mark {
	background: none;
	padding: 0;
	color: var(--primary-color);
}

// image lazyload
img {
	max-width: 100%;
	height: auto;

	&.lazyload {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s, visibility 0.3s;

		&.lazyloaded {
			opacity: 1;
			visibility: visible;
		}
	}
}

// cols style
.row-10 {
	@include grid-gutter(1rem);
}

.row-20 {
	@include grid-gutter(2rem);
}

.row-40 {
	@include grid-gutter(4rem);
}

.row-xl-100 {
	@include media-breakpoint-up(xl) {
		@include grid-gutter(100px);
	}
}

// list-unstyled
.list-unstyled {
	list-style: none;
	margin: 0;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	text-align: center;

	> li {
		width: 100%;

		+ li {
			padding-top: 1.5rem;
		}

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				text-decoration: underline;
				color: inherit;
			}
		}
	}
}

// dev 2

// dev 3

// dev 4

// dev 5
