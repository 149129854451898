/* map-section */
.map-section,
.reservation-form {
	--base-bg: #{$light};

	.color-theme-red & {
		--base-bg: #{$pot-pourri};
	}
}

.map-section {
	--search-width: auto;
	--opt-label-color: #{$blue};
	--hover-op: 0.75;

	position: relative;
	// z-index: 0;
	height: calc(var(--vh, 1vh) * 100 - var(--header-height));

	@include media-breakpoint-up(lg) {
		--search-width: 46.6rem;
	}

	.color-theme-red & {
		--opt-label-color: #{$red};
	}

	.map {
		width: 100%;
		height: 100%;

		.map-tooltip {
			display: block;
			padding: 1.2rem 1.5rem;
			border-radius: 1rem;
			border: 0.2rem solid var(--opt-label-color);
			background: var(--base-bg);
			text-transform: uppercase;
			font-weight: 500;
			font-size: 1.1rem;

			a {
				text-decoration: none;
			}

			h4 {
				font-size: 2.4rem;
				margin-bottom: 1rem;
			}

			p {
				margin-bottom: 0;
			}

			.marked {
				color: var(--base-color);
				font-size: 1.4rem;
			}
		}

		.infoBox {
			> img {

			}
		}
	}

	.map-filter,
	.search-result {
		.btn-back {
			display: flex;
			align-items: center;
			width: 3.2rem;
			height: 3.2rem;
			padding: 0.3rem;
			margin-right: 1rem;
			color: inherit;
			font-size: inherit;
			line-height: inherit;

			&::before {
				display: none;
			}

			.fa-arrow-left {
				&::before {
					content: '';
					display: block;
					width: 1.9rem;
					height: 1.6rem;
					mask: url(#{$images}ico-arrow-left-001.svg) no-repeat;
					mask-size: 100% auto;
					background: currentColor;
				}
			}
		}
	}

	.map-filter {
		position: absolute;
		top: 0;
		left: 0.9rem;
		right: 0.9rem;
		z-index: 20;
		display: flex;
		
		@include media-breakpoint-down(lg) {
			top: 0.8rem;
			z-index: 110;
		}

		@include media-breakpoint-up(lg) {
			top: 1.8rem;
		}
		
		> form {
			display: flex;
			flex-grow: 1;
			flex-shrink: 1;
		}
		
		.map-search {
			position: relative;
			z-index: 20;
			width: var(--search-width);
			padding: 0.5rem 0 0.5rem 1.1rem;
			border-radius: 0.6rem;
			background: var(--base-bg);//$light;
			box-shadow: 0 0.1rem 0.8rem 0 rgba($black, 0.25);
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			align-items: center;

			@include media-breakpoint-down(lg) {
				height: 5rem;
			}

			@include media-breakpoint-up(lg) {
				flex-grow: 0;
				flex-shrink: 0;
				padding: 0.5rem 1.6rem 0.5rem 1.7rem;
				border-radius: 0.4rem;
			}

			label {
				vertical-align: top;
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 1.1rem;
				font-size: 1rem;
				line-height: 1;
				
				@include media-breakpoint-up(lg) {
					margin-right: 2.8rem;
				}

				.fa-magnifying-glass {
					vertical-align: top;

					&::before {
						content: '';
						display: block;
						width: 1.8rem;
						height: 1.8rem;
						mask: url(#{$images}ico-zoom-001.svg) no-repeat;
						mask-size: 100% auto;
						background: currentColor;
					}
				}
			}

			input[type="search"] {
				@include hideTimesFromSearch();
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
				background: none;
				border: 0;
				border-radius: 0;
				font-weight: 500;
				padding: 0.5rem 0.5rem 0.8rem;

				&::placeholder {
					opacity: 0.5;
					color: inherit;
				}
			}

			.reset-options {
				@include reset-link();
				
				@include media-breakpoint-down(lg) {
					font-size: 0;
					line-height: 0;
					width: 3.2rem;
					height: 3.2rem;
					padding: 0;
					flex-grow: 0;
					flex-shrink: 0;
				}

				&::after {
					@include media-breakpoint-down(lg) {
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: auto;
					}
				}
			}

			&:not(.selected) {
				.reset-options {
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		&.opened-options {
			@include media-breakpoint-down(lg) {
				z-index: 150;
			}
		}

		&:not(.opened-options) {
			.map-options {
				@include media-breakpoint-down(lg) {
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		&.filled-options {
			.btn-opts {
				background: var(--opt-label-color);
				color: $light;
			}
		}

		&:not(.filled-options) {
			.map-options {
				.map-options-head {
					.reset-options {
						opacity: 0;
						visibility: hidden;
					}
				}
			}
		}

		.map-options {
			@include media-breakpoint-down(lg) {
				@include position-over(150);
				position: fixed;
				background: var(--base-bg);//$light;
				display: flex;
				flex-direction: column;
				padding: 0 $container-side-padding-sm;
				transition: all 0.3s;
				transition-property: opacity, visibility;
			}

			@include media-breakpoint-up(lg) {
				flex-grow: 1;
				flex-shrink: 1;
				display: flex;
				padding-left: 0.2rem;
			}

			.map-options-head {
				@include media-breakpoint-down(lg) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: var(--header-height);
					margin-left: -$container-side-padding-sm;
					margin-right: -$container-side-padding-sm;
					margin-bottom: 2.7rem;
					padding-left: $container-side-padding-sm;
					padding-right: $container-side-padding-sm;
					flex-grow: 0;
					flex-shrink: 0;
					background: var(--opt-label-color);
					color: $white;
				}

				.reset-options {
					@include reset-link();
					color: inherit;
				}
				
				* + .reset-options {
					margin-left: 1rem;
				}

				* + .btn-apply {
					margin-left: 1rem;
				}

				.btn-apply {
					color: inherit;
					position: relative;
					vertical-align: top;
					display: inline-flex;
					align-items: center;
					text-decoration: none;
					text-transform: uppercase;
					font-weight: 900;
					font-size: 1.6rem;
					line-height: 1.25;
					font-family: $AmsiProCond;

					.fa-arrow-right {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						width: 2.7rem;

						&::before {
							content: '';
							display: block;
							width: 1.9rem;
							height: 1.6rem;
							mask: url(#{$images}ico-arrow-left-001.svg) no-repeat;
							mask-size: 100% auto;
							background: currentColor;
							transform: scaleX(-1);
						}
					}
				}
			}

			.map-options-body {
				@include media-breakpoint-down(lg) {
					flex-grow: 1;
					flex-shrink: 1;
					overflow-y: auto;
					overflow-x: hidden;
					padding-bottom: 3rem;
					padding-right: 1.2rem;
					margin-right: -1.2rem;
				}

				@include media-breakpoint-up(lg) {
					display: flex;
					flex-grow: 1;
					flex-shrink: 1;
				}

				> .dropdown {
					@include media-breakpoint-up(lg) {
						margin-left: 0.4rem;
					}
	
					.opt-opener {
						@include media-breakpoint-up(lg) {
							height: 6rem;
							padding: 0.3rem 1.1rem;
							border-radius: 0.4rem;
						}

						@include media-breakpoint-up(xl) {
							padding-top: 0.8rem;
							padding-bottom: 0.8rem;
						}
	
						.opt-name {
							font-size: 1.5rem;
							line-height: 1.067;
						}
					}
				}
			}
		}

		.btn-opts {
			flex-grow: 0;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--opt-label-color);
			margin-left: 0.6rem;
			width: 5rem;
			height: 5rem;
			background: var(--base-bg);//$light;
			box-shadow: 0 0.1rem 0.8rem 0 rgba($black, 0.25);
			border-radius: 0.6rem;
			text-decoration: none;

			@include media-breakpoint-up(lg) {
				display: none;
			}

			.fa-sliders {
				&::before {
					content: '';
					display: block;
					width: 3rem;
					height: 3rem;
					mask: url(#{$images}ico-settings-001.svg) no-repeat 50% 50%;
					mask-size: 100% auto;
					background: currentColor;
				}
			}
		}

		.check-cols {
			@include media-breakpoint-up(lg) {
				position: relative;
				padding-right: 50%;
				min-height: 25.6rem;
			}

			.check-group + .check-group {
				margin-top: 0 !important;
			}

			.sub-group {
				@include media-breakpoint-down(lg) {
					columns: 2;
					margin-top: 1.4rem;
					margin-bottom: 2.1rem;
				}

				@include media-breakpoint-up(lg) {
					position: absolute;
					top: 0;
					left: 60.7%;
					right: 0;
					bottom: 0;
					overflow-y: auto;
					overflow-x: hidden;
					padding-top: 0.9rem;
					@include custom-scroll();
					background: var(--base-bg);//$light;
				}

				&:not(.shown) {
					position: absolute !important;
					opacity: 0 !important;
					visibility: hidden !important;
					// display: none !important;
				}

				> * {
					@include media-breakpoint-down(lg) {
						page-break-inside: avoid;
					}
				}

				.wpcf7-form-control-wrap {
					@include media-breakpoint-down(lg) {
						margin-bottom: 1.5rem;
					}
				}
			}
		}
	}

	.result-list {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		width: var(--search-width);
		background: var(--base-bg);//$light;
		border-radius: 1.6rem 1.6rem 0 0;
		box-shadow: 0 0.1rem 0.8rem 0 rgba($black, 0.25);

		@include media-breakpoint-down(lg) {
			z-index: 110;
			display: flex;
			flex-direction: column;
		}

		@include media-breakpoint-up(lg) {
			overflow-y: auto;
			overflow-x: hidden;
			@include custom-scroll();
			top: 8.4rem;
			left: 0.9rem;
			bottom: 1.5rem;
			border-radius: 0.4rem;
			padding: 6.1rem 2.5rem 2rem;
		}

		&.opened {
			@include media-breakpoint-down(lg) {
				// height: calc(var(--vh, 1vh) * 100 - 0.8rem);
				top: calc(var(--header-height) * -1 + 0.8rem);
			}

			.result-opener {
				&::before {
					transform: scaleY(-1);
				}
			}

			.full-result-list {
				@include media-breakpoint-down(lg) {
					display: block;
				}
			}

			.temp-result {
				display: none;
			}
		}

		&.shown-details:not(.opened) {
			.full-result-list {
				display: none;
			}
		}

		.result-opener {
			position: relative;
			display: block;
			height: 5rem;

			@include media-breakpoint-down(lg) {
				flex-grow: 0;
				flex-shrink: 0;
			}

			@include media-breakpoint-up(lg) {
				display: none;
			}

			&::before {
				content: '';
				@include position-over(0);
				bottom: 0.7rem;
				margin: auto;
				width: 1.3rem;
				height: 0.8rem;
				mask: url(#{$images}ico-chevron-up-001.svg) no-repeat;
				mask-size: 100% auto;
				background: currentColor;
				transition: transform 0.3s;
			}
		}

		.search-result-head {
			@include media-breakpoint-down(lg) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-grow: 0;
				flex-shrink: 0;
				height: var(--header-height);
				margin-bottom: (0.8rem + 5rem + 4.8rem);
				padding-left: $container-side-padding-sm;
				padding-right: $container-side-padding-sm;
			}

			@include media-breakpoint-up(lg) {
				// display: block;
				display: none;
			}
		}

		.result-list-entry {
			@include media-breakpoint-down(lg) {
				padding: 0 1.7rem;
				flex-grow: 1;
				flex-shrink: 1;
				overflow-y: auto;
				overflow-x: hidden;
			}
		}

		.full-result-list {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	.point-preview {
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 1.067;
		color: inherit;

		p {
			margin-bottom: 0;
		}

		h4 {
			font-size: 2.6rem;
			line-height: 1.193;
			margin-bottom: 0.3rem;

			@include media-breakpoint-up(lg) {
				margin-bottom: 0.2rem;
			}
		}

		.marked {
			color: var(--base-color);
		}

		.base-col {
			flex-grow: 1;
			flex-shrink: 1;

			@include media-breakpoint-down(lg) {
				font-size: 1.3rem;
			}
		}

		.extra-col {
			flex-grow: 0;
			flex-shrink: 0;
			max-width: 50%;
			padding-top: 0.9rem;
			padding-left: 1rem;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 1.231;

			@include media-breakpoint-up(lg) {
				padding-right: 0.3rem;
			}
		}
	}

	.result-group {
		--base-color: var(--opt-label-color);

		& + .result-group {
			margin-top: 4rem;

			@include media-breakpoint-up(lg) {
				margin-top: 3.1rem;
			}
		}

		h3 {
			text-transform: uppercase;
			font-size: 2.6rem;
			line-height: 1.193;
			margin-bottom: 2.4rem;
			color: var(--base-color);

			@include media-breakpoint-up(lg) {
				margin-bottom: 1.9rem;
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				width: 100%;
				display: flex;
				border-bottom: 1px solid;
				margin-bottom: 2.2rem;
				padding-bottom: 1.7rem;

				@include media-breakpoint-up(lg) {
					margin-bottom: 1.4rem;
					padding-bottom: 1.2rem;
				}
			}
		}
	}

	.temp-result {
		.point-preview {
			margin-bottom: 2rem;
		}
	}

	.search-result {
		display: none !important;
		transition: all 0.3s;
		transition-property: opacity, visibility;

		@include media-breakpoint-down(lg) {
			@include position-over(155);
			top: calc(var(--header-height) * -1);
			background: var(--base-bg);//$light;
			display: flex;
			flex-direction: column;
			padding-left: $container-side-padding-sm;
			padding-right: $container-side-padding-sm;
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			overflow-y: auto;
			overflow-x: hidden;
			width: var(--search-width);
			background: var(--base-bg);//$light;
			border-radius: 1.6rem 1.6rem 0 0;
			box-shadow: 0 0.1rem 0.8rem 0 rgba($black, 0.25);
			@include custom-scroll();
			top: 8.4rem;
			left: 0.9rem;
			bottom: 1.5rem;
			border-radius: 0.4rem;
			padding: 6.1rem 2.5rem 2rem;
		}

		.search-result-head {
			@include media-breakpoint-down(lg) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-grow: 0;
				flex-shrink: 0;
				height: var(--header-height);
				margin-bottom: (0.8rem + 5rem + 4.8rem);
			}

			@include media-breakpoint-up(lg) {
				// display: block;
				display: none;
			}
		}

		.search-result-body {
			@include media-breakpoint-down(lg) {
				overflow-y: auto;
				overflow-x: hidden;
				flex-grow: 1;
				flex-shrink: 1;
			}
		}
	}

	&.opened-search-result {
		.map-filter {
			@include media-breakpoint-down(lg) {
				z-index: 160;
			}

			.btn-opts {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}

		.result-list {
			// opacity: 0;
			// visibility: hidden;
			@include media-breakpoint-down(lg) {
				top: calc(var(--header-height) * -1);
				z-index: 155;
				border-radius: 0;
			}

			.result-opener {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			.full-result-list {
				@include media-breakpoint-down(lg) {
					display: block;
				}
			}
		}
	}

	&:not(.opened-search-result) {
		.search-result {
			opacity: 0;
			visibility: hidden;
		}

		.result-list {
			.search-result-head {
				display: none;
			}
		}
	}
}

.check-group {
	.wpcf7-form-control-wrap {
		--lh: 1;//1.25;
		--check-size: 1.2rem;
		
		display: block;
		margin-left: 0;
		margin-bottom: 2rem;
		text-transform: uppercase;
		font-weight: 900;
		font-size: 1.6rem;
		line-height: var(--lh);
		font-family: $AmsiProCond;

		@include media-breakpoint-up(lg) {
			margin-bottom: 1.4rem;
		}

		.wpcf7-form-control {
			display: block;
			margin-left: 0;
		}

		.wpcf7-list-item {
			display: block;
			margin-left: 0;

			&.with-icon {
				label {
					.wpcf7-list-item-label {
						display: inline-flex;
						align-items: center;

						.lbl-icon {
							width: 2.6rem;
							flex-grow: 0;
							flex-shrink: 0;
							margin-right: 0.9rem;
						}
					}
				}
			}
		}

		&.unselectable {
			label {
				.wpcf7-list-item-label {
					color: rgba($brown, 0.3) !important;
				}
			}
		}

		label {
			display: inline-block;
			vertical-align: top;
			position: relative;
			padding-left: 2.5rem;

			@include media-breakpoint-up(lg) {
				padding-left: 2.1rem;
			}

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: var(--check-size);
				height: var(--check-size);
				margin-top: calc(var(--check-size) * -0.5);
				border-radius: 0.3rem;
				background: $white;
			}

			input[type="checkbox"],
			input[type="radio"] {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;

				&:checked {
					& ~ .wpcf7-list-item-label {
						// color: $brown;
						opacity: 1;

						&::before {
							border-color: var(--opt-label-color);
							background-color: var(--opt-label-color);
							opacity: 1;
						}
					}
				}

				&:disabled {
					& ~ .wpcf7-list-item-label {
						// color: rgba($brown, 0.5);
						opacity: 0.5;
					}
				}
			}

			.wpcf7-list-item-label {
				display: inline-block;
				vertical-align: top;
				// color: rgba($brown, 0.5);
				opacity: 0.3;
				transition: opacity 0.3s;

				&::before {
					content: '';
					position: absolute;
					top: 50%;//calc(var(--lh) * 0.5em);
					left: 0;
				}

				&::before {
					width: var(--check-size);//0.75em;
					height: var(--check-size);//0.75em;
					margin-top: calc(var(--check-size) * -0.5);//(-0.75em * 0.5);
					border-radius: 0.3rem;
					// background: $white;
					border: 1px solid transparent;
					box-shadow: inset 0 0 0 0.1rem $white;
					transition: border-color 0.3s, background 0.3s;
					opacity: 0;
				}
			}
		}

		&.size-lg {
			--lh: 1.348;

			color: var(--opt-label-color);
			font-size: 2.3rem;
			margin-bottom: 1rem;
			
			@include media-breakpoint-up(lg) {
				--lh: 1.193;

				font-size: 2.6rem;
				margin-bottom: 0.9rem;
			}

			label {
				@include media-breakpoint-up(lg) {
					padding-left: 1.7rem;
				}
			}
		}
	}

	&.cols-three {
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 2.8rem;
			margin-left: -0.6rem;
			margin-right: -0.6rem;
		}

		> .wpcf7-form-control-wrap {
			@include media-breakpoint-up(lg) {
				width: 33.33%;
				padding-left: 0.6rem;
				padding-right: 0.6rem;
				margin-bottom: 2.6rem;
			}
		}
	}
}

.reservation-form .dropdown,
.map-options .map-options-body > .dropdown {
	@include media-breakpoint-down(lg) {

	}
	
	@include media-breakpoint-up(lg) {
		flex-grow: 1;
		flex-shrink: 1;
		width: calc(100% - 0.4rem);
		display: flex;
	}

	.msg-item {
		font-size: 1.4rem;
		margin-bottom: 1rem;
	}

	&.trigger-auto-location {
		.msg-item {
			display: none;
		}

		&.auto-location-disabled {
			.msg-item {
				display: block;
			}

			.check-group {
				opacity: 0.25;
				pointer-events: none;
			}
		}
	}

	.opt-opener {
		text-decoration: none;
		width: 100%;

		@include media-breakpoint-down(lg) {
			pointer-events: none;
			display: block;
			margin-bottom: 1.7rem;
			font-size: 1.5rem;
			line-height: 1.067;
		}

		@include media-breakpoint-up(lg) {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			height: 6.9rem;
			flex-direction: column;
			padding: 1.1rem 1.5rem 0.9rem;
			border-radius: 0.6rem;
			background: var(--base-bg);//$light;
			box-shadow: 0 0.1rem 0.8rem 0 rgba($black, 0.25);
		}

		.opt-name {
			display: block;
			color: var(--opt-label-color);
			text-transform: uppercase;
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 1.067;
			font-family: $AmsiProCond;

			@include media-breakpoint-up(lg) {
				margin-right: -0.7rem;
				font-size: 1.9rem;
			}
		}

		.opt-val {
			margin-top: auto;
			position: relative;
			// display: flex;
			// align-items: center;
			overflow: hidden;

			@include media-breakpoint-down(lg) {
				display: none;
			}

			.opt-selected {
				position: absolute;
				top: 50%;
				left: 0;
				right: 2.4rem;
				overflow: hidden;
				margin-top: (-1.25 * 0.5em);
				height: (1.25 * 1em);
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: 0%;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				font-weight: 900;
				font-size: 1.6rem;
				line-height: 1.25;
				font-family: $AmsiProCond;
				white-space: nowrap;

				&.with-multi {
					padding-right: 2.5rem;

					.opt-val-item {
						flex-grow: 0;
						flex-shrink: 0;

						&.long-item {
							@include text-truncate();
							max-width: 100%;
						}
					}
				}

				&:not(.with-multi) {
					display: block;

					.opt-val-item:first-child {
						@include text-truncate();
						max-width: 100%;
					}
				}

				.opt-val-item {
					display: block;
					margin-right: 0.2rem;

					&.hidden {
						display: none;
					}

					&.num {
						position: absolute;
						top: 0;
						right: 0;
						margin-right: 0;
						background: var(--base-bg);//$light;
					}
				}
			}

			.opt-placeholder {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 1.6rem;
				line-height: 1.25;
				color: rgba($brown, 0.3);
				transition: all 0.3s;
			}

			.opt-reset {
				position: relative;
				margin-left: auto;
				display: block;
				width: 2.4rem;
				height: 2.4rem;
				color: var(--opt-label-color);
				transition: all 0.3s;
				cursor: pointer;

				&::before {
					content: '';
					@include position-over();
					width: 1.6rem;
					height: 1.6rem;
					margin: auto;
					mask: url(#{$images}ico-times-001.svg) no-repeat;
					mask-size: 100% auto;
					background: currentColor;
				}

				&:hover {
					opacity: var(--hover-op);
				}
			}
		}
	}
	
	> .dropdown-menu {
		box-shadow: none;
		min-width: 100%;
		border: 0;
		
		@include media-breakpoint-down(lg) {
			position: static !important;
			transform: none !important;
			margin: 0 !important;
			display: block !important;
			background: none;
			border-radius: 0;
			padding: 0;
			width: 100% !important;
			overflow: visible;
			overflow-y: visible;
			max-height: none;
			height: auto;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 0.7rem !important;
			border-radius: 0.6rem;
			background: var(--base-bg);//$light;
			padding: 1.6rem 1.3rem 0.6rem;
			box-shadow: 0 0 0 0.2rem var(--opt-label-color);
		}

		&.w2 {
			@include media-breakpoint-up(lg) {
				width: 57.8rem;
				padding-left: 2rem;
			}

			&.align-lg-center {
				@include media-breakpoint-up(lg) {
					left: 50% !important;
					margin-left: (-57.8rem * 0.5 !important);
				}
			}
		}

		&.w3 {
			@include media-breakpoint-up(lg) {
				width: 62.8rem;
				margin-right: -4rem !important;
				padding-left: 2.2rem;
			}
		}

		&.w4 {
			&.align-lg-center {
				@include media-breakpoint-up(lg) {
					width: 23.7rem;
				}

				&.align-lg-center {
					@include media-breakpoint-up(lg) {
						left: 50% !important;
						margin-left: (-23.7rem * 0.5 !important);
					}
				}
			}
		}

		.hold {
			@include media-breakpoint-down(lg) {
				position: absolute;
				top: 0;//1.9rem;
				right: 0;
				width: 50%;
				display: flex;
				justify-content: flex-end;
			}

			@include media-breakpoint-up(lg) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 2rem;
			}

			.check-group {
				.wpcf7-form-control-wrap {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.reset-options {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}
		
		.check-group {
			& + .check-group {
				margin-top: 3.4rem;
			}
		}

		.footer-hold {
			@include media-breakpoint-down(lg) {
				display: none;
			}

			@include media-breakpoint-up(lg) {
				text-align: right;
				margin-top: 3.9rem;
			}
			
			.reset-options {
				@include media-breakpoint-up(lg) {
					margin-right: -0.3rem;
				}
			}
		}

		.reset-options {
			@include reset-link();

			@include media-breakpoint-up(lg) {
				padding-top: 0.1rem;
			}

			&::after {
				@include media-breakpoint-up(lg) {
					margin-top: -0.7rem;
				}
			}
		}
	}

	&.selected {
		.opt-opener {
			.opt-val {
				.opt-placeholder {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	&:not(.selected) {
		.opt-opener {
			.opt-val {
				.opt-reset {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	& + .dropdown {
		@include media-breakpoint-down(lg) {
			border-top: 1px solid;
			margin-top: 2.7rem;
			padding-top: 1.9rem;
		}

		> .dropdown-menu {
			.hold {
				@include media-breakpoint-down(lg) {
					top: 1.9rem;
				}
			}
		}
	}
}
