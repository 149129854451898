// btn-back-bar
.btn-back-bar {
	position: relative;
	padding-top: 9rem;

	.btn-back-bar-body {
		position: relative;
		min-height: 0.1rem;
	}

	.btn-back {
		position: absolute;
		z-index: 10;
		top: 0.4rem;
		left: -0.8rem;
	}

	@include media-breakpoint-down(lg) {
		padding-top: 0;
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		background-color: var(--light-color);

		.btn-back-bar-body {
			min-height: 0;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		.btn-back {
			position: relative;
			top: auto;
			left: auto;
			margin-left: -0.3rem;
		}
	}
}
