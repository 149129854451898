// btn
.btn {
	position: relative;
	// display: inline-flex;
	// vertical-align: top;
	// align-items: center;
	// justify-content: center;
	display: inline-block;
	vertical-align: top;
	outline: 0 !important;
	--bs-btn-padding-x: 4.7rem;
	--bs-btn-padding-y: 1.5rem;
	--bs-btn-font-size: 2.6rem;
	--bs-btn-line-height: 1.154;
	--bs-btn-font-weight: #{$font-family-secondary-weight};
	--bs-btn-border-width: 0.1rem;
	--bs-btn-font-family: #{$font-family-secondary};
	--bs-btn-border-radius: 1rem;
	letter-spacing: 0;
	text-transform: uppercase;
	min-width: 25rem;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	transition: all 0.3s;
	white-space: normal;
	box-shadow: none;
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
	font-size: var(--bs-btn-font-size);
	line-height: var(--bs-btn-line-height);
	font-weight: var(--bs-btn-font-weight);
	font-family: var(--bs-btn-font-family);
	color: var(--bs-btn-color);
	border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
	border-radius: var(--bs-btn-border-radius);
	background-color: var(--bs-btn-bg);

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		box-shadow: none !important;
		color: var(--bs-btn-hover-color);
		background-color: var(--bs-btn-hover-bg);
		border-color: var(--bs-btn-hover-border-color);
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.ico {
		display: inline-block;
		vertical-align: top;
		width: 2.4rem;
		height: 2.4rem;
		position: relative;
		overflow: hidden;
		margin-top: 0.3rem;
		margin-left: 2.4rem;

		img,
		svg {
			@include center-image(100%, 100%);
		}
	}

	&-lg {
		line-height: 1.192;
		padding: 1.8rem 7rem;
	}

	&-md {
		min-width: 20.4rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&-sm {
		@include button-size(0px, 0px, 0px, 0);
	}

	&-primary {
		--bs-btn-color: var(--light-color);
		--bs-btn-bg: var(--primary-color);
		--bs-btn-border-color: var(--primary-color);
		--bs-btn-hover-color: var(--light-color);
		--bs-btn-hover-bg: var(--primary-shade-color);
		--bs-btn-hover-border-color: var(--primary-shade-color);
		--bs-btn-active-color: var(--light-color);
		--bs-btn-active-bg:  var(--primary-shade-color);
		--bs-btn-active-border-color:  var(--primary-shade-color);

		.ico {
			svg {
				path {
					fill: var(--light-color);
				}
			}
		}
	}

	&-outline-primary {
		--bs-btn-color: var(--secondary-color);
		--bs-btn-bg: transparent;
		--bs-btn-border-color: var(--secondary-color);
		--bs-btn-hover-color: var(--light-color);
		--bs-btn-hover-bg: var(--secondary-color);
		--bs-btn-hover-border-color: var(--secondary-color);
		--bs-btn-active-color: var(--light-color);
		--bs-btn-active-bg:  var(--secondary-color);
		--bs-btn-active-border-color:  var(--secondary-color);
	}

	input {
		@include input-position-over();
	}

	@include media-breakpoint-down(xxxl) {
		--bs-btn-font-size: 2.4rem;
	}

	@include media-breakpoint-down(lg) {
		--bs-btn-padding-x: 1.5rem;
		--bs-btn-padding-y: 1.3rem;
		--bs-btn-font-size: 2.3rem;
		--bs-btn-line-height: 1.174;
		min-width: 21.9rem;

		&-lg {
			line-height: 1.174;
			padding: 1.3rem 1.5rem;
		}

		&-md {
			min-width: 20.4rem;
		}
	}

	// dev 2

	// dev 3

	// dev 4

	// dev 5
}

// dev 2

// dev 3

// dev 4

// dev 5
