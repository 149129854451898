// services-list
.services-list {
	list-style: none;
	margin: 0 -0.7rem;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> li {
		padding: 0.7rem;
	}

	figure {
		margin: 0;
		position: relative;
		overflow: hidden;
		border-radius: 0.8rem;
		width: 5rem;
		background-color: var(--ico-color);

		&::before {
			content: "";
			display: block;
			padding-top: 100%;
		}

		img {
			@include center-image(100%, 100%);

			border-radius: 0.8rem;
			mix-blend-mode: luminosity;
		}
	}

	@include media-breakpoint-down(lg) {
		margin-left: -0.9rem;
		margin-right: -0.9rem;

		> li {
			padding: 0.9rem;
		}

		figure {
			width: 4.5rem;
		}
	}
}
