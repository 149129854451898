// cursor-circle-btn
.cursor-circle-btn {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	text-align: center;
	width: 12rem;
	height: 12rem;
	overflow: hidden;
	border-radius: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	color: var(--light-color);
	background-color: var(--primary-color);
	font-size: 1.6rem;
	line-height: 1.2;
	text-decoration: none;
	opacity: 0;
	pointer-events: none;
	transform: translate(-50%, -50%);

	.cursor-circle-btn-visible & {
		opacity: 1;
	}

	h4 {
		color: inherit;
		margin-bottom: 0;
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		font-family: inherit;
	}
}
