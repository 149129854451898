// btn-back
.btn-back {
	display: inline-block;
	vertical-align: top;
	position: relative;
	overflow: hidden;

	@include blank-text();

	width: 3rem;
	height: 3rem;

	&::before {
		content: "";
		position: absolute;
		z-index: 5;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1.9rem;
		height: 1.5rem;
		mask-image: url(#{$images}arrow-left-brown-01.svg);
		mask-repeat: no-repeat;
		mask-size: 1.9rem 1.5rem;
		background-color: currentColor;
	}

	&:hover {
		opacity: $hover-opacity;
	}
}
