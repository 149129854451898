// goverlay
.goverlay {
	background-color: rgba($white, 0.92);
}

.glightbox-mobile {
	.goverlay {
		background-color: rgba($white, 0.92);
	}
}

// glightbox-clean
.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
	background-color: var(--secondary-color);
	opacity: 1;

	&:hover {
		background-color: $body-color;
	}
}
