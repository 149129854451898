// mobile-fixed-buttons-section
.mobile-fixed-buttons-section {
	position: fixed;
	z-index: 95;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 0.6rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	transition: all 0.3s;

	.scrolled-end & {
		opacity: 0;
		pointer-events: none;
	}
}
