// header
#header {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--light-color);

	.container-fluid {
		padding-left: 1.8rem;
		padding-right: 1.8rem;
	}

	.header-user-ico {
		position: relative;
		overflow: hidden;
		max-width: 2.6rem;
		flex: 0 0 2.6rem;
		margin-left: 3.2rem;

		> a {
			display: block;
			width: 100%;
			position: relative;
			overflow: hidden;

			&:hover {
				opacity: $hover-opacity;
			}
		}

		img,
		svg {
			display: block;
			width: 100%;

			@include img-fluid();
		}

		svg {
			path {
				fill: var(--primary-color);
			}
		}
	}

	.header-dropdown {
		&.header-dropdown-social {
			margin-left: 1.6rem;
		}

		&.header-dropdown-languages {
			margin-left: 2rem;
		}
	}

	.header-social {
		list-style: none;
		margin: 0 -1.3rem;
		padding: 0;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		> li {
			padding-left: 1.3rem;
			padding-right: 1.3rem;
			display: flex;

			> a {
				position: relative;
				overflow: hidden;
				display: flex;

				img,
				svg {
					display: inline-block;
					vertical-align: top;
					max-width: 2.6rem;
					max-height: 2.3rem;
				}

				svg {
					path {
						fill: var(--primary-color);
					}
				}

				&:hover {
					opacity: $hover-opacity;
				}
			}
		}
	}

	.header-languages {
		list-style: none;
		margin: 0 -1rem;
		padding: 0;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-size: 1.9rem;
		line-height: 1.053;
		font-weight: $font-family-secondary-weight;
		font-family: $font-family-secondary;
		text-transform: uppercase;

		> li {
			padding-left: 1rem;
			padding-right: 1rem;

			> a {
				text-decoration: none;
				color: inherit;

				&:hover {
					text-decoration: none;
					opacity: $hover-opacity;
				}
			}

			&.active {
				> a {
					color: var(--primary-color);

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.single-page & {
			display: none !important;
		}

		.container-fluid {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		.btn-menu {
			width: 4rem;
			height: 4rem;
			position: relative;
			overflow: hidden;
			background: none;
			padding: 0;
			border: 0;
			margin-right: -1rem;

			@include blank-text();

			> span {
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				max-width: 2.4rem;
				height: 0.3rem;
				margin-left: auto;
				margin-right: auto;
				background-color: var(--primary-color);
				border-radius: 0.5rem;
				transition: all 0.3s;

				@include blank-text();

				&:nth-child(1) {
					margin-top: 1rem;
				}

				&:nth-child(2) {
					margin-top: 1.9rem;
				}

				&:nth-child(3) {
					margin-top: 2.8rem;
				}
			}
		}
	}
}

// dev 2

// dev 3

// dev 4

// dev 5
