// product-cards-list
.product-cards-list {
	list-style: none;
	margin: 0 -1.5rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	> li {
		display: flex;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		max-width: 33.33%;
		flex: 0 0 33.33%;

		> a {
			text-decoration: none;
			color: inherit;
			display: flex;
			width: 100%;

			.product-card {
				transition: all 0.3s;
			}

			&:hover,
			&:focus {
				text-decoration: none;
				color: inherit;

				.product-card {
					opacity: $hover-opacity;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		margin-left: -$container-side-padding-xl;
		margin-right: -$container-side-padding-xl;
		padding-left: calc($container-side-padding-xl);
		padding-right: calc($container-side-padding-xl);
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		> li {
			max-width: 32.2rem;
			flex: 0 0 32.2rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	@include media-breakpoint-down(md) {
		margin-left: -$container-side-padding-sm;
		margin-right: -$container-side-padding-sm;
		padding-left: calc($container-side-padding-sm);
		padding-right: calc($container-side-padding-sm);
	}
}
