/* reservation-form */
.reservation-form {
	--opt-label-color: #{$blue};

	@include media-breakpoint-down(lg) {
		margin-top: 1.8rem;
	}

	.color-theme-red & {
		--opt-label-color: #{$red};
	}

	.form-cell {
		@include media-breakpoint-down(lg) {
			border-radius: 0.6rem;
			background: var(--base-bg);//$light;
			color: $body-color;
		}

		.dropdown {
			& + .dropdown {
				@include media-breakpoint-down(lg) {
					border-top: 1px solid $brown;
					margin-top: 0;
					padding-top: 0;
				}
			}

			.opt-opener {
				@include media-breakpoint-down(lg) {
					display: flex;
					flex-direction: column;
					padding: 1.1rem 1.6rem;
					height: 7.2rem;
					margin-bottom: 0;
					pointer-events: all;
				}

				.opt-name {
					@include media-breakpoint-down(lg) {
						font-size: 1.9rem;
						
					}
				}

				.opt-val {
					display: block;

					.opt-reset {
						cursor: pointer;
					}
				}
			}
		}
	}

	.reservation-form-options {
		@include media-breakpoint-down(lg) {
			@include position-over(150);
			position: fixed;
			top: var(--header-height);
			background: var(--base-bg);//$light;
			color: $body-color;
			display: flex;
			flex-direction: column;
			padding: 0 $container-side-padding-sm;
			transition: all 0.3s;
			transition-property: opacity, visibility;
		}

		.forms-options-head {
			@include media-breakpoint-down(lg) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: var(--header-height);
				margin-left: -$container-side-padding-sm;
				margin-right: -$container-side-padding-sm;
				margin-bottom: 2.7rem;
				padding-left: $container-side-padding-sm;
				padding-right: $container-side-padding-sm;
				flex-grow: 0;
				flex-shrink: 0;
				background: var(--opt-label-color);
				color: $white;
			}

			.reset-options {
				@include reset-link();
				color: inherit;
			}
			
			* + .reset-options {
				margin-left: 1rem;
			}

			* + .btn-apply {
				margin-left: 1rem;
			}

			.btn-apply {
				color: inherit;
				position: relative;
				vertical-align: top;
				display: inline-flex;
				align-items: center;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: 900;
				font-size: 1.6rem;
				line-height: 1.25;
				font-family: $AmsiProCond;

				.fa-arrow-right {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					width: 2.7rem;

					&::before {
						content: '';
						display: block;
						width: 1.9rem;
						height: 1.6rem;
						mask: url(#{$images}ico-arrow-left-001.svg) no-repeat;
						mask-size: 100% auto;
						background: currentColor;
						transform: scaleX(-1);
					}
				}
			}
		}

		.forms-options-body {
			@include media-breakpoint-down(lg) {
				flex-grow: 1;
				flex-shrink: 1;
				overflow-y: auto;
				overflow-x: hidden;
				padding-bottom: 3rem;
				padding-right: 1.2rem;
				margin-right: -1.2rem;
			}

			> .row {
				> [class*="col"] + [class*="col"] {
					@include media-breakpoint-down(lg) {
						border-top: 1px solid;
						padding-top: 1.9rem;
					}
				}
			}
		}
	}

	.row {
		@include grid-gutter(0.4rem);
	}

	.col-01 {
		@include media-breakpoint-up(lg) {
			width: 18.9%;
		}
	}

	.col-02 {
		@include media-breakpoint-up(lg) {
			width: 62.2%;
		}
	}

	.col-03 {
		@include media-breakpoint-up(lg) {
			width: 19.4%;
		}
	}

	.col-04 {
		@include media-breakpoint-up(lg) {
			width: 80.6%;
		}
	}

	.btn-hold {
		@include media-breakpoint-down(lg) {
			margin-top: 0.8rem;
		}

		@include media-breakpoint-up(lg) {
			position: relative;
			left: 0.5rem;
		}
	}

	.btn {
		--bs-btn-border-radius: 0.7rem;
	}

	&:not(.opened-options) {
		.reservation-form-options {
			@include media-breakpoint-down(lg) {
				opacity: 0;
					visibility: hidden;
			}
		}
	}

	&:not(.filled-options) {
		.reservation-form-options {
			.forms-options-head {
				.reset-options {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}
}
